import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({

    state(){
        return {
            // 存放的公共数据，所有的组件都可以拿到
            abc:'this is a msg in vuex',
            money:100,
            host:'http://10.9.30.87:5000',
            product:{
                title:'新增', // 默认是新增，点击编辑时赋值为“编辑”,
                rowData:{},
            },
            userinfo:{
                name:localStorage.getItem('username'),
                role:'',
            },
            access_token:localStorage.getItem('access_token'),
            refresh_token:localStorage.getItem('refresh_token'),
            session_id:localStorage.getItem('s_id'),   //存储session_id
            showPaymentDialog: false, // 是否显示支付弹窗
        }
    },
    mutations:{   // 修改vuex中存放的公共数据
        addMoney(state,num){
            state.money += num
        },
        setProductTitle(state,title){
            state.product.title = title
        },
        setProductRowData(state,playload){
            state.product.rowData = playload
        },
        setUserInfo(state,playload){
            state.userinfo = playload
            localStorage.setItem('userinfo',JSON.stringify(playload))
        },
        setAccessToken(state,playload){
            state.access_token = playload
            localStorage.setItem('access_token',playload)
        },
        setRefreshToken(state,playload){
            state.refresh_token = playload
            localStorage.setItem('refresh_token',playload)
        },
        setSessionId(state,playload){
            state.session_id = playload
            localStorage.setItem('s_id',playload)
        },
        // 是否展示支付弹窗
        setShowPaymentDialog(state, payload) {
            state.showPaymentDialog = payload;
        }
    }
})

export default store