import VueRouter from "vue-router";


// 这里放的都是路由的配置
const router = new VueRouter({
    mode: 'hash',       // 默认是hash模式，可改成history
    routes: [
        {
            path: '/',
            component: () => import('../pages/login/Index'), // 懒加载,
            meta: { title: '数字化运营管理平台', needLogin: false },
            children: [

            ]
        },
        {
            path: '/login',
            component: () => import('../pages/login/Index'),
            meta: { title: '登录' }
        },
        {
            path: '/register',
            component: () => import('../pages/login/register'),
            meta: { title: '注册' }
        },
        {
            path: '/forgetPwd',
            component: () => import('../pages/login/forgetPwd'),
            meta: { title: '忘记密码' }
        },
        {
            path: '/dashboard',
            component: () => import('../pages/Layout.vue'),
            meta: { needLogin: true },
            children: [
                {
                    path: '/',
                    component: () => import('../pages/dy/list/content/VideoList.vue'),
                    meta: { title: '首页' },
                },
                {
                    path: 'group',
                    component: () => import('../pages/dy/Index.vue'),
                    meta: { title: '客户群' },
                    children: [
                        {
                            path: 'customer_group',
                            component: () => import('../pages/dy/list/Index.vue'),
                            meta: { title: '客户群互动' }
                        },
                        {
                            path: 'label_manage',
                            component: () => import('../pages/dy/list/LabelManage.vue'),
                            meta: { title: '客户群标签' }
                        },
                        {
                            path: 'group_list',
                            component: () => import('../pages/dy/list/GroupList.vue'),
                            meta: { title: '客户群列表' }
                        },
                    ]
                },
                {
                    path: 'customer',
                    component: () => import('../pages/dy/Index.vue'),
                    meta: { title: '客户管理' },
                    children: [
                        {
                            path: 'customer_list',
                            component: () => import('../pages/dy/list/customer/CustomerList.vue'),
                            meta: { title: '客户管理' }
                        },
                        {
                            path: 'label_manage',
                            component: () => import('../pages/dy/list/customer/LabelManage.vue'),
                            meta: { title: '标签管理' }
                        },
                        {
                            path: 'blacklist',
                            component: () => import('../pages/dy/list/customer/BlackList.vue'),
                            meta: { title: '黑名单' }
                        },
                    ]
                },
                {
                    path: 'content',
                    component: () => import('../pages/dy/Index.vue'),
                    meta: { title: '内容中心' },
                    children: [
                        {
                            path: 'video_list',
                            component: () => import('../pages/dy/list/content/VideoList.vue'),
                            meta: { title: '内容中心' }
                        },
                        {
                            path: 'label_manage',
                            component: () => import('../pages/dy/list/customer/LabelManage.vue'),
                            meta: { title: '标签管理' }
                        },
                    ]
                },
                {
                    path: 'configCenter',
                    component: () => import('../pages/dy/Index.vue'),
                    meta: { title: '配置中心' },
                    children: [
                        {
                            path: 'config_center',
                            component: () => import('../pages/dy/list/AcountInfo.vue'),
                            meta: { title: '账号信息' }
                        },
                        {
                            path: 'auth_acount',
                            component: () => import('../pages/dy/list/AuthAcount.vue'),
                            meta: { title: '授权管理' }
                        },
                    ]
                },
                {
                    path: 'pics',
                    component: () => import('../pages/pics/Index.vue'),
                    meta: { title: '图片' },
                    children: [
                        {
                            path: 'translation',
                            component: () => import('../pages/pics/list/Index.vue'),
                            meta: { title: '图片翻译' }
                        },
                        {
                            path: 'direct_translation',
                            component: () => import('../pages/pics/list/YiJianFanYi.vue'),
                            meta: { title: '一键图片翻译' }
                        }

                    ]
                },
                {
                    path: 'text',
                    component: () => import('../pages/text/Index'),
                    meta: { title: '文字' },
                    children: [
                        {
                            path: 'bdyd_translation',
                            component: () => import('../pages/text/list/Index'),
                            meta: { title: '百度、有道翻译' }
                        },
                        {
                            path: 'gpt_translation',
                            component: () => import('../pages/text/list/AiTranslation.vue'),
                            meta: { title: 'AI文字翻译' }
                        }

                    ]
                },
                {
                    path: 'product',
                    component: () => import('../pages/product/Index.vue'),
                    meta: { title: '产品' },
                    children: [
                        {
                            path: 'list',
                            component: () => import('../pages/product/list/Index.vue'),
                            meta: { title: '产品列表页面' }
                        },
                        {
                            path: 'addproduct',
                            component: () => import('../pages/product/list/ProductPage.vue'),
                            meta: { activeName: '/product/list' }
                        },
                    ]
                },
                {
                    path: 'plugin',
                    component: () => import('../pages/plugin/Index'),
                    meta: { title: '插件管理' },
                    children: [
                        {
                            path: 'list',
                            component: () => import('../pages/plugin/list/Index'),
                            meta: { title: '插件管理页面' }
                        },
                    ]

                },
                {
                    path: 'setting',
                    component: () => import('../pages/setting/Index'),
                    meta: { title: '设置' },
                    children: [
                        {
                            path: 'translation_setting',
                            component: () => import('../pages/setting/list/Index'),
                            meta: { title: '翻译设置' }
                        },
                        {
                            path: 'resetPwd',
                            component: () => import('../pages/login/resetPwd'),
                            meta: { title: '修改密码' }
                        },
                        {
                            path: 'personalCenter',
                            component:  () => import('../pages/login/personalCenter'),
                            meta: { title: '个人中心' }
                        },
                    ]
                },
            ]
        },

    ]
})

// 路由跳转前的操作,注意这里不能使用匿名箭头函数，否则this是undefined
router.beforeEach((to, from, next) => {


    // /*
    // 先判断访问的路由是否需要登陆
    if (to.matched.some(record => record.meta.needLogin)) {
        console.log('需要登陆')
        // 判断是否登陆:判断是否有token
        if (localStorage.getItem('access_token')) {
            next()
        }
        else {
            // next()
            next('/login')
        }

    }
    // 访问的路由不需要登录，就是登录页
    else {
        // 但是要判断下是否有登录态，如果有就去dashboard('/')，没有就next()跳转至登录页面
        if (localStorage.getItem('access_token')) {
            // next('/dashboard')
            next()
        }
        else {
            next()
        }
    }

})

// 路由跳转后的操作
router.afterEach((to, from) => {
    // console.log(to,from)
    to.meta.title ? document.title = to.meta.title : document.title = '后台管理系统'
})



export default router