const base = {
    login_path:'/dy_api/login',
    bd_chat: '/dy_api/bd-chat-single',
    azure_chat: '/dy_api/single-chat-process-a',
    yd_pic_translation: '/dy_api/yd-pic-content',
    yd_textranslation: '/dy_api/text-translation',
    bd_textTranslation: '/dy_api/bd-text-translation',
    bd_chat_single:'/dy_api/bd-chat-single',
    ali_pics_translation: '/dy_api/ali-pic-translate-direct',
    register:'/dy_api/register',
    sendEmail:'/dy_api/send-verification-code',
    forgetPwd: '/dy_api/forgot-password',
    resetPwd:'/dy_api/change-password',
    create_charge_qr_code:'/dy_api/create-charge',
    query_trade_result:'/dy_api/query-trade-package-duration',
    get_user_login_status_path:'/dy_api/get_user_login_status',
    get_user_profile_path:'/dy_api/profile',
    get_dy_fans_list_path:'/dy_api/get_fans_list',   //获取群列表
    get_dy_group_member_path:'/dy_api/get_group_member',  // 获取群成员
    get_dy_group_member_qr_path:'/dy_api/get_group_member_qrCode', // 获取qr_code
    get_file_list_path:'/dy_api/get-pics-templeate-list',  // 获取视频或图片列表
    generate_dy_QR_code:'/dy_api/content-to-dy',  // 生成dy分享码
    delete_file_path:'/dy_api/delete_file',
    create_folder_path:'/dy_api/create_folder', // 创建文件夹分组
    get_folder_list_path:'/dy_api/list_folders', // 获取文件夹分组
    delete_folder_path:'/dy_api//delete_folder', //删除文件夹及文件夹下的文件
    rename_folder_path:'/dy_api/rename_folder',
    batch_generate_schema_path:'/dy_api/batch-content-to-dy', // 批量生成schema
    batch_generate_qrcode_png_path:'/dy_api/batch_generate_QR_code_png',
    get_all_file_in_folder_path:'/dy_api/get-pics-templeate-list-no-paginate', // 查询出所有数据，没有分页
    
}

export default base